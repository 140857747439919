.workbox{
    background-color: rgb(239, 239, 239);
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 80%;
    min-width: 24rem;
    max-width: 50rem;
    border-radius: 5px;
    box-shadow: 0.3rem 0.3rem 0.7rem black;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
}

.workbox__titleBox{
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 0px;
}

.workbox__title{
    font-size:1.2rem;
    font-weight: 600;
    color: rgb(49, 49, 156);
}

.workbox__contentBox{
    align-content: flex-start;
    margin-top: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    vertical-align: center;
    align-items: center;
}

.workbox__clickBox{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workbox__picBox{
    width: 100%;
    text-align: center;
    min-width: 15rem;
    max-width: 20rem;
}

.workbox__pic{
    width: 80%;
    border: 0.2rem;
    border-color: black;
    border-style: solid;
}

.workbox__description{
    padding: 0.5rem;
    display: flex;
    width: 100%;
    font-size: 1.1rem;
    color: rgb(49, 49, 156);
    text-align: left;
    min-width: 10rem;
    max-width: 20rem;
}

.workbox__click{
    font-size: 1.4rem;
    background-color: white;
    border-color: black;
    color: rgb(49, 49, 156);
}

.workbox__click:hover{
    background-color: lightgrey;
}