.home{
    margin-top: 6rem;
    height: 90vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
}

.home__picBox{
    margin-top:2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.home__picBoxSpecific{
    width: 40%;
    max-width: 20rem;
    min-width: 10rem;
}

.home__pic{
    border-radius: 50%;
    width: 100%;
    box-shadow: 1rem 1rem 4rem black;
}

.home__contact{
    width: 60%;
    justify-content: left;
    text-align: left;
    vertical-align: center;
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    min-width: 20rem;
}

.home__logoBox{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
}

.home__logoBoxPic{
    border-radius: 50%;
    width: 4rem;
    margin-left: 1.5rem;
    margin-right: .5rem;
    box-shadow: 0.3rem 0.3rem 0.5rem black;
}

.home__logoBoxPic:hover{
    opacity: 0.6;
}

.home__title{
    margin-left: 2rem;
    font-weight: 700;
    font-size: 1rem;
    color: rgb(43, 43, 147);;
}

.home__textBox{
    margin-top: 1rem;
    width: 100%;
}

.home__text{
    padding: 1rem;
    font-size: 1.2rem;
    color: rgb(49, 49, 156);
}

