.about{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: left;
}

.about__text{
    font-size: 1.2rem;
    color: rgb(5, 5, 175);
}