.education{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.education__title{
    color: rgb(35, 35, 134);
}